import { FunctionComponent, PropsWithChildren } from 'react';
import { useSnackbar } from 'notistack';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { gql, useMutation } from '@apollo/client';
import { Form, Field, Formik, FieldProps, FormikHelpers } from 'formik';
import { FormValues } from 'hooks/useFormUtils';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { HiddenSubmitButton } from 'shared/HiddenSubmitButton';
import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { CustomNumberFormat } from 'shared/CustomNumberFormat';
import {
  AutoTopUpAccordionFormBillingProfileData,
  AUTO_TOP_UP_ACCORDION_FORM_BILLING_PROFILE_FRAGMENT,
} from './fragments';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { Button, TextField, MenuItem, Typography, Alert, Stack } from '@mui/material';

interface UpdateBillingProfileAutoTopUpData {
  updateBillingProfile: {
    billingProfile: AutoTopUpAccordionFormBillingProfileData & Pick<BillingProfileNode, 'id' | 'modified'>;
  };
}

interface UpdateBillingProfileAutoTopUpVariables {
  input: {
    autoTopupAmount: string | null;
    autoTopupThresholdAmount: string | null;
  };
}

const UPDATE_BILLING_PROFILE_AUTO_TOP_UP = gql`
  mutation updateBillingProfileAutoTopUp($input: UpdateBillingProfileInput!) {
    updateBillingProfile(input: $input) {
      billingProfile {
        id
        modified
        ...autoTopUpAccordionFormBillingProfileFragment
      }
    }
  }
  ${AUTO_TOP_UP_ACCORDION_FORM_BILLING_PROFILE_FRAGMENT}
`;

interface VatFormValues extends FormValues {
  autoTopupAmount: string;
  autoTopupThresholdAmount: string;
}

interface AutoTopUpAccordionFormProps {
  onClose: () => void;
  billingProfile: AutoTopUpAccordionFormBillingProfileData;
}

// FIXME
const MAXIMUM_AMOUNT_WITH_VAT_DEFAULT = '5000';

export const AutoTopUpAccordionForm: FunctionComponent<PropsWithChildren<AutoTopUpAccordionFormProps>> = ({
  onClose,
  billingProfile,
}) => {
  const onError = useDefaultOnError();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage, formatNumber } = useExtendedIntl();

  const [updateBillingProfileAutoTopUp] = useMutation<
    UpdateBillingProfileAutoTopUpData,
    UpdateBillingProfileAutoTopUpVariables
  >(UPDATE_BILLING_PROFILE_AUTO_TOP_UP, {
    onError: (err) => onError(err, { defaultErrorMessage: formatMessage({ id: 'wallet-auto-top-up.error-message' }) }),
    onCompleted: () => {
      enqueueSnackbar(formatMessage({ id: 'wallet-auto-top-up.success-message' }), { variant: 'success' });
      onClose();
    },
  });

  const onFormikSubmit = async (values: VatFormValues, actions: FormikHelpers<VatFormValues>) => {
    const input = { ...values };

    await updateBillingProfileAutoTopUp({ variables: { input } });

    actions.setSubmitting(false);
  };

  const { currency, autoTopupAmount, autoTopupThresholdAmount, autoTopupThresholdAmountLimits } = billingProfile;

  const { minimumAmountWithVat, maximumAmountWithVat } = billingProfile.topupAmountLimits ?? {
    minimumAmountWithVat: '0',
    maximumAmountWithVat: MAXIMUM_AMOUNT_WITH_VAT_DEFAULT,
  };

  const validateTopupAmount = (value: string) => {
    if (Number(value) < Number(minimumAmountWithVat) || Number(value) > Number(maximumAmountWithVat)) {
      const minimum = formatNumber(Number(minimumAmountWithVat), { style: 'currency', currency });
      const maximum = formatNumber(Number(maximumAmountWithVat), { style: 'currency', currency });
      return formatMessage({ id: 'topup-amount.error' }, { minimum, maximum });
    }

    return '';
  };

  const initialValues = {
    autoTopupAmount: autoTopupAmount ?? '',
    autoTopupThresholdAmount: autoTopupThresholdAmount ?? '',
  };

  return (
    <Formik<VatFormValues> enableReinitialize initialValues={initialValues} onSubmit={onFormikSubmit}>
      {({ isSubmitting, handleSubmit, errors: formErrors }) => (
        <Form onSubmit={handleSubmit}>
          {formErrors.generic && <Alert severity="error">{formErrors.generic}</Alert>}
          <Stack direction="row" columnGap={1}>
            <Field name="autoTopupThresholdAmount">
              {({ field, form: { errors } }: FieldProps<VatFormValues>) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={isSubmitting}
                  error={Boolean(errors.autoTopupThresholdAmount)}
                  label={formatMessage({ id: 'wallet.minimum' })}
                  helperText={
                    errors.autoTopupThresholdAmount ? (
                      <>{errors.autoTopupThresholdAmount}</>
                    ) : (
                      formatMessage({ id: 'wallet.when-balance-reaches' })
                    )
                  }
                >
                  {autoTopupThresholdAmountLimits &&
                    autoTopupThresholdAmountLimits.suggestedAmounts.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatNumber(Number(value), { style: 'currency', currency })}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Field name="autoTopupAmount" validate={validateTopupAmount}>
              {({ field, form: { errors } }: FieldProps<VatFormValues>) => (
                <TextField
                  {...field}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={isSubmitting}
                  error={Boolean(errors.autoTopupAmount)}
                  label={formatMessage({ id: 'wallet.top-up' })}
                  InputProps={{ inputComponent: CustomNumberFormat }}
                  helperText={
                    errors.autoTopupThresholdAmount ? (
                      <>{errors.autoTopupThresholdAmount}</>
                    ) : (
                      formatMessage({ id: 'wallet.when-balance-reaches' })
                    )
                  }
                />
              )}
            </Field>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {formatMessage({ id: 'wallet.plus-vat' })}
            </Typography>
            <Stack direction="row" columnGap={1}>
              <Button onClick={onClose}>{formatMessage({ id: 'shared.cancel' })}</Button>
              <ProgressButton
                type="submit"
                variant="outlined"
                loading={isSubmitting}
                disabled={Boolean(formErrors.autoTopupAmount)}
              >
                {formatMessage({ id: 'shared.save' })}
              </ProgressButton>
            </Stack>
          </Stack>
          <HiddenSubmitButton />
        </Form>
      )}
    </Formik>
  );
};
