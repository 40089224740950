import { gql, LazyQueryHookOptions, useQuery } from '@apollo/client';
import { config } from 'appConfig';
import { SESSION_CONTEXT_QUERY_FRAGMENT, SessionContextQueryFragmentData } from 'components/App/session/SessionContext';
import {
  NAVIGATION_MENU_HEADER_QUERY_FRAGMENT,
  NavigationMenuHeaderQueryFragmentData,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/NavigationMenu/NavigationMenuHeader/fragments';
import {
  NUM_INITIAL_TEAMS,
  TEAMS_MENU_QUERY_FRAGMENT,
  TeamsMenuQueryFragmentData,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/TeamsMenu/TeamsMenu';
import {
  REFERRAL_ANNOUNCEMENT_QUERY_FRAGMENT,
  ReferralAnnouncementQueryFragmentData,
} from 'shared/ReferralAnnouncement';
import { USER_TEAM_AVATAR_QUERY_FRAGMENT, UserTeamAvatarQueryFragmentData } from 'shared/UserTeamAvatar';
import { FRONTEND_SETTING_CONNECTION_FRAGMENT, FrontendSettingConnectionFragmentData } from 'graphql/fragments';
import { APP_SESSION_LOCALE_QUERY_FRAGMENT, AppSessionLocaleQueryFragmentData } from 'hooks/useAppSessionLocale';
import {
  REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT,
  RedirectUserThemePartnerQueryFragmentData,
} from 'hooks/useRedirectUserThemePartner';
import {
  SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT,
  ShouldShowFirstBusinessPromptQueryFragmentData,
} from 'hooks/useShouldShowFirstBusinessPrompt';
import { USER_UTILS_BOOT_QUERY_FRAGMENT, UserUtilsBootQueryFragmentData } from 'hooks/useUtilsBoot';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { APIPermission } from 'typeDeclarations/enums';
import { PermissionNode, UserTeamsNode } from 'typeDeclarations/graphql/nodes';
import { MAX_SETTINGS } from 'utils/Settings/constants';
import { USER_TRACKING_QUERY_FRAGMENT, UserTrackingQueryFragmentData } from 'userTracking/useUserTracking';

/**
 * This component fetches a lot of important data at once for caching and optimization purposes.
 */

/**
 * Query, fragment and respective types created for the optimization purpose
 * during the login process
 * WIP
 * In the future we might want to fetch more "simple" data from the session user and team such as:
 * 1) billing profile info (much needed on the wallet and billing and payments section)
 * 2) user info needed e.g on the account settings page
 */

export const SESSION_QUERY_FRAGMENT = gql`
  fragment sessionQueryFragment on Query {
    ...teamsMenuQueryFragment
    ...userTrackingQueryFragment
    ...userUtilsBootQueryFragment
    ...userTeamAvatarQueryFragment
    ...sessionContextQueryFragment
    ...appSessionLocaleQueryFragment
    ...referralAnnouncementQueryFragment
    ...navigationMenuHeaderQueryFragment
    ...redirectUserThemePartnerQueryFragment
    ...shouldShowFirstBusinessPromptQueryFragment
    isLoggedIn
    getContextPermissions(permissions: $permissions) {
      id
      _id
    }
    sessionUserTeamRelation {
      id
      frontendSettings(first: ${MAX_SETTINGS}, frontend: $frontend) {
        ...frontendSettingConnectionFragment
      }
    }
  }
  ${TEAMS_MENU_QUERY_FRAGMENT}
  ${USER_TRACKING_QUERY_FRAGMENT}
  ${USER_UTILS_BOOT_QUERY_FRAGMENT}
  ${SESSION_CONTEXT_QUERY_FRAGMENT}
  ${USER_TEAM_AVATAR_QUERY_FRAGMENT}
  ${APP_SESSION_LOCALE_QUERY_FRAGMENT}
  ${FRONTEND_SETTING_CONNECTION_FRAGMENT}
  ${REFERRAL_ANNOUNCEMENT_QUERY_FRAGMENT}
  ${NAVIGATION_MENU_HEADER_QUERY_FRAGMENT}
  ${REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT}
  ${SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT}
`;

export type SessionQueryFragmentData = TeamsMenuQueryFragmentData &
  UserTrackingQueryFragmentData &
  UserUtilsBootQueryFragmentData &
  UserTeamAvatarQueryFragmentData &
  SessionContextQueryFragmentData &
  AppSessionLocaleQueryFragmentData &
  NavigationMenuHeaderQueryFragmentData &
  ReferralAnnouncementQueryFragmentData &
  RedirectUserThemePartnerQueryFragmentData &
  ShouldShowFirstBusinessPromptQueryFragmentData & {
    isLoggedIn: boolean;
    getContextPermissions: Array<Pick<PermissionNode, 'id' | '_id'>>;
    sessionUserTeamRelation:
      | (Pick<UserTeamsNode, 'id'> & { frontendSettings: FrontendSettingConnectionFragmentData })
      | null;
  };

const SESSION_QUERY = gql`
  query sessionQuery($teamsAmount: Int!, $frontend: String!, $permissions: [String!]!) {
    ...sessionQueryFragment
  }
  ${SESSION_QUERY_FRAGMENT}
`;

export type SessionQueryVariables = {
  frontend: string;
  teamsAmount: number;
  permissions: APIPermission[];
};

type SessionQueryData = SessionQueryFragmentData;

export function useGetSessionData(
  options?: Omit<LazyQueryHookOptions<SessionQueryData, SessionQueryVariables>, 'onError' | 'variables'>,
) {
  const onError = useDefaultOnError();

  return useQuery<SessionQueryData, SessionQueryVariables>(SESSION_QUERY, {
    ...options,
    onError,
    variables: {
      frontend: config.name,
      teamsAmount: NUM_INITIAL_TEAMS,
      permissions: Object.values(APIPermission),
    },
  });
}
