import { gql, useQuery } from '@apollo/client';
import { CampaignNode } from 'typeDeclarations/graphql/nodes';

// As of 24/06/2024, the only non-supported language is Greek.
export const ACS_SUPPORTED_CONTENT_LANGUAGES = new Set<string>([
  'ca', // Catalan
  'de', // German
  'en', // English
  'es', // Spanish
  'fi', // Finnish
  'fr', // French
  'he', // Hebrew
  'it', // Italian
  'nl', // Dutch
  'pl', // Polish
  'pt', // Portuguese
]);

const CAMPAIGN_CONTENT_LANGUAGE_QUERY = gql`
  query campaignContentLanguageQuery($campaignUglyId: ID, $campaignPrettyId: String) {
    campaign(id: $campaignUglyId, _id: $campaignPrettyId) {
      id
      modified
      contentLanguage
    }
  }
`;

interface CampaignContentLanguageQueryVariables {
  campaignUglyId?: string;
  campaignPrettyId?: string;
}

type CampaignContentLanguageQueryData = {
  campaign: null | Pick<CampaignNode, 'id' | 'modified' | 'contentLanguage'>;
};

export function useShouldShowACS({ campaignUglyId, campaignPrettyId }: CampaignContentLanguageQueryVariables) {
  const variables: CampaignContentLanguageQueryVariables = {};

  if (campaignUglyId) {
    variables.campaignUglyId = campaignUglyId;
  } else {
    variables.campaignPrettyId = campaignPrettyId;
  }

  const { data, error, loading } = useQuery<CampaignContentLanguageQueryData, CampaignContentLanguageQueryVariables>(
    CAMPAIGN_CONTENT_LANGUAGE_QUERY,
    { variables },
  );

  if (loading) return;
  if (error || !data) return false;

  const { campaign } = data;
  if (!campaign) {
    window.location.href = '/not-found';
    return null;
  }

  const { contentLanguage } = campaign;
  if (!contentLanguage) return false;

  return ACS_SUPPORTED_CONTENT_LANGUAGES.has(contentLanguage);
}
