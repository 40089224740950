import { gql } from '@apollo/client';
import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

// Not removing this from this file yet
export const SESSION_CONTEXT_QUERY_FRAGMENT = gql`
  fragment sessionContextQueryFragment on Query {
    sessionUser {
      id
      modified
      ddtId
      theme
      locale
      isStaff
    }
    sessionTeam {
      id
      modified
    }
  }
`;

export interface SessionContextQueryFragmentData {
  user: Pick<UserNode, 'id' | 'ddtId' | 'theme' | 'locale' | 'isStaff'>;
  team: Pick<TeamNode, 'id'>;
}
