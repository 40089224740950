import { FunctionComponent, useCallback, useState, PropsWithChildren } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { MiniDialog } from 'shared/MiniDialog';

import { ProgressIconButton } from 'shared/ProgressButtons/ProgressIconButton';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { GraphQLObject, Connection, Node } from 'typeDeclarations/graphql/base-types';
import { CACHED_BILLING_PROFILE_QUERY, CachedBillingProfileSessionTeamData } from 'shared/PaymentSources/sharedQueries';
import { UserNode } from 'typeDeclarations/graphql/nodes';
import { STRIPE_PAYMENT_SOURCES_PAGE_SIZE } from 'shared/PaymentSources/constants';
import {
  PAYMENT_SOURCE_MUTATION_BILLING_PROFILE_FRAGMENT,
  PaymentSourceUnionNode,
} from 'shared/PaymentSources/sharedFragments';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { ButtonProps } from '@mui/material';

interface DeletePaymentSourceTeamPayload extends GraphQLObject {
  team: Node & {
    modified: string;
    billingProfile: Node & {
      modified: string;
      otherStripeCardPaymentSources: Connection<Node & { modified: string } & PaymentSourceUnionNode>;
    };
  };
}

interface DeletePaymentSourceUserPayload extends GraphQLObject {
  user: Pick<UserNode, 'id' | 'modified' | 'numStripeCardPaymentSources' | 'numStripeCardPaymentSourcesMax'>;
}

interface DeleteStripePaymentSourceMutationData extends GraphQLObject {
  sessionTeam: DeletePaymentSourceTeamPayload;
  sessionUser: DeletePaymentSourceUserPayload;
}

interface DeletePaymentSourceMutationVariables {
  amount: number;
  cursor: string | null;
  input: {
    paymentSourceId: string;
    force: boolean;
  };
}

const DELETE_PAYMENT_SOURCE_MUTATION = gql`
  mutation deletePaymentSourceMutation($input: DeletePaymentSourceInput!, $amount: Int!, $cursor: String) {
    deletePaymentSource(input: $input) {
      clientMutationId
    }
    sessionTeam(input: {}) {
      team {
        id
        modified
        billingProfile {
          id
          modified
          ...paymentSourceMutationBillingProfileFragment
        }
      }
    }
    sessionUser(input: {}) {
      user {
        id
        modified
        numStripeCardPaymentSources
        numStripeCardPaymentSourcesMax
      }
    }
  }
  ${PAYMENT_SOURCE_MUTATION_BILLING_PROFILE_FRAGMENT}
`;

interface DeletePaymentSourceButtonProps extends ButtonProps {
  iconButton?: boolean;
  paymentSourceId: string;
  primary?: boolean;
}

export const DeletePaymentSourceButton: FunctionComponent<PropsWithChildren<DeletePaymentSourceButtonProps>> = ({
  iconButton = false,
  paymentSourceId,
  primary = false,
  ...rest
}) => {
  const { formatMessage } = useExtendedIntl();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onError = useDefaultOnError();

  const handleOnCompleted = useCallback(() => {
    if (primary) {
      setDialogOpen(false);
    }
  }, [primary]);

  const [deleteStripePaymentSourceMutation, { client, loading }] = useMutation<
    DeleteStripePaymentSourceMutationData,
    DeletePaymentSourceMutationVariables
  >(DELETE_PAYMENT_SOURCE_MUTATION, {
    onError: (err) => onError(err),
    onCompleted: handleOnCompleted,
  });

  const deleteStripePaymentSource = useCallback(() => {
    const cachedData = client.readQuery<CachedBillingProfileSessionTeamData>({
      query: CACHED_BILLING_PROFILE_QUERY,
    });

    if (!cachedData) {
      onError(new Error('No cachedBillingProfileData for the deleteStripePaymentSource mutation.'));
      return;
    }

    deleteStripePaymentSourceMutation({
      variables: {
        amount: STRIPE_PAYMENT_SOURCES_PAGE_SIZE,
        cursor: null,
        input: {
          paymentSourceId,
          force: true,
        },
      },
    });
  }, [client, deleteStripePaymentSourceMutation, onError, paymentSourceId]);

  return (
    <>
      {iconButton ? (
        <ProgressIconButton onClick={handleDialog} loading={loading} {...rest}>
          <DeleteIcon />
        </ProgressIconButton>
      ) : (
        <ProgressButton onClick={handleDialog} loading={loading} variant="text" {...rest}>
          {formatMessage({ id: 'shared.delete' })}
        </ProgressButton>
      )}
      <MiniDialog
        variant="delete"
        open={dialogOpen}
        loading={loading}
        onClose={handleCloseDialog}
        onClick={deleteStripePaymentSource}
        title={`${formatMessage({ id: 'shared.delete' })}?`}
        primaryButtonText={formatMessage({ id: 'shared.delete' })}
        secondaryButtonText={formatMessage({ id: 'shared.cancel' })}
        description={formatMessage({ id: 'shared.delete-warning-message' })}
      />
    </>
  );
};
