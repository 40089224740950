import { FunctionComponent, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useReactiveVar } from '@apollo/client';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useUserTracking } from 'userTracking/useUserTracking';
import { permissionsVar, userThemeVar } from 'client/cache';
import { APIPermission } from 'typeDeclarations/enums';
import {
  CAMPAIGN_CONTEXT_PROVIDER_CAMPAIGN_FRAGMENT,
  CampaignContextProviderCampaignFragmentData,
} from '../../CampaignContext/fragments';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { AdInterfaceNode, AdsStates, AdvertiserNode, BudgetNode, CampaignNode } from 'typeDeclarations/graphql/nodes';
import { GraphQLObject } from 'typeDeclarations/graphql/base-types';
import { CampaignContext } from '../../CampaignContext/CampaignContext';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { walletVar } from 'shared/Wallet/WalletDialog';
import { useCampaignBudgetWalletIssues } from 'components/Budget/hooks/useCampaignBudgetWalletIssues';
import { exists, isNull } from 'typeDeclarations/typeGuards';
import { Tooltip } from '@mui/material';
import { isCurrentAppTheme } from 'appConfig';

interface PublishCampaignTreeVariables {
  input: {
    campaignId: string;
  };
}

interface PublishCampaignTreePayload extends GraphQLObject {
  campaign: Pick<CampaignNode, 'id' | 'isDraft' | 'modified'> & {
    advertisingPlatformStates: Pick<AdsStates, 'aggregateState'>;
    budget: Pick<BudgetNode, 'id' | 'isDraft' | 'modified'>;
    advertiser: Pick<AdvertiserNode, 'id' | 'isDraft' | 'modified'>;
  } & CampaignContextProviderCampaignFragmentData;
  publishedAds: Array<Pick<AdInterfaceNode, 'id' | 'isDraft' | 'modified'>>;
}

interface PublishCampaignTreeResponse {
  publishCampaignTree: PublishCampaignTreePayload;
}

const PUBLISH_CAMPAIGN_TREE = gql`
  mutation publishCampaignTree($input: PublishCampaignTreeInput!) {
    publishCampaignTree(input: $input) {
      campaign {
        id
        modified
        isDraft
        budget {
          id
          modified
          isDraft
        }
        advertiser {
          id
          modified
          isDraft
        }
        advertisingPlatformStates {
          aggregateState
        }
        ...campaignContextProviderCampaignFragment
      }
      publishedAds {
        id
        isDraft
        modified
      }
    }
  }
  ${CAMPAIGN_CONTEXT_PROVIDER_CAMPAIGN_FRAGMENT}
`;

export const LaunchCampaignButton: FunctionComponent = () => {
  const navigate = useNavigate();
  const onError = useDefaultOnError();
  const appTheme = userThemeVar();
  const { enqueueSnackbar } = useSnackbar();
  const { captureEvent } = useUserTracking();
  const { formatMessage } = useExtendedIntl();

  const { isDraft, campaignId, hasBlockingIssues, externalCampaignId } = useContext(CampaignContext);

  const isSiteeAppTheme = isCurrentAppTheme('sitee');
  const permissions = useReactiveVar(permissionsVar);
  const budgetWalletIssues = useCampaignBudgetWalletIssues({ campaignUglyId: campaignId });

  const [publishCampaign, { loading }] = useMutation<PublishCampaignTreeResponse, PublishCampaignTreeVariables>(
    PUBLISH_CAMPAIGN_TREE,
    {
      onCompleted: () => {
        enqueueSnackbar(formatMessage({ id: 'shared.campaign-publish-success' }), { variant: 'success' });

        if (isSiteeAppTheme) {
          window.location.href = isNull(externalCampaignId)
            ? 'https://my.sitee.io/campaign/help'
            : `https://my.sitee.io/campaign/fundwallet?accountnumber=${externalCampaignId}`;
        } else {
          navigate('/');
        }

        if ((appTheme === 'default' || appTheme === 'leadzai') && budgetWalletIssues) {
          walletVar({ isOpen: true, issue: budgetWalletIssues });
        }
      },
      onError: (err) => onError(err, { defaultErrorMessage: formatMessage({ id: 'shared.campaign-publish-error' }) }),
      variables: {
        input: {
          campaignId,
        },
      },
    },
  );

  const buttonIsDisabled =
    !(APIPermission.PublishCampaigns in permissions) ||
    !(APIPermission.PerformChanges in permissions) ||
    !isDraft ||
    hasBlockingIssues ||
    exists(budgetWalletIssues);

  const handleOnButtonClick = () => {
    publishCampaign();
    captureEvent({ eventName: 'launchCampaign' });
  };

  let tooltipTitle = null;

  if (isSiteeAppTheme) {
    tooltipTitle = formatMessage({ id: 'wallet.top-up-redirect' });
  }

  if (exists(budgetWalletIssues)) {
    tooltipTitle = formatMessage({ id: 'budget-step-issues.finish' });
  }

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <span>
        <ProgressButton loading={loading} variant="contained" disabled={buttonIsDisabled} onClick={handleOnButtonClick}>
          {formatMessage({ id: 'shared.finish' })}
        </ProgressButton>
      </span>
    </Tooltip>
  );
};
