import { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState, PropsWithChildren } from 'react';
import { ApolloError, gql, useApolloClient, useReactiveVar } from '@apollo/client';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';

import { isError, isNull, isUndefined } from 'typeDeclarations/typeGuards';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { CounterHelperText } from 'shared/CounterHelperText';

import { getObjectKeys } from 'utils/getObjectKeys';
import { APIPermission } from 'typeDeclarations/enums';
import { TeamUsersList } from './TeamUsersList/TeamUsersList';
import { TeamUser } from './types';
import { TeamFormTeamFragmentData, TeamFormUserFragmentData } from './fragment';
import { GlobalFormikState } from 'shared/GlobalFormikState';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { DefaultDialogContent, DefaultDialogActions } from 'shared/DefaultDialog/DefaultDialog';
import { permissionsVar } from 'client/cache';
import { FormValues } from 'hooks/useFormUtils';
import { TextField, Alert } from '@mui/material';

const MAX_TEAM_NAME_LENGTH = 255;
const MAX_TEAM_USER_EMAIL_LENGTH = 255;

const StyledForm = styled.form`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: column;
`;

// ----------------------- VALIDATE USER QUERY -------------------- //
const TEAM_FORM_VALIDATE_USER_QUERY = gql`
  query teamFormValidateUserQuery($email: String!) {
    user(email: $email) {
      id
      modified
      name
      email
      picture {
        id
        url
      }
    }
  }
`;

interface TeamFormValidateUserQueryVariables {
  email: string;
}

interface TeamFormValidateUserQueryData {
  user: null | TeamUser;
}

export interface TeamFormValues extends FormValues {
  teamName: string;
  teamOwner: TeamUser;
  teamUsers: Record<string, TeamUser>; // userId -> TeamUser
}

interface TeamFormProps {
  onCancelled?: () => void;
  user: TeamFormUserFragmentData;
  // If team is null, that means we are creating. Else, we are updating an existing team.
  fetchMoreTeamUsers?: () => void;
  fetchingMoreTeamUsers?: boolean;
  team: TeamFormTeamFragmentData | null;
  onSubmit: (formValues: TeamFormValues) => Promise<unknown>;
}

export const TeamForm: FunctionComponent<PropsWithChildren<TeamFormProps>> = ({
  user,
  team,
  onSubmit,
  onCancelled,
  fetchMoreTeamUsers,
  fetchingMoreTeamUsers,
}) => {
  const client = useApolloClient();
  const onError = useDefaultOnError();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useExtendedIntl();

  const permissions = useReactiveVar(permissionsVar);
  const canPerformChanges = APIPermission.PerformChanges in permissions;

  const [userEmail, setUserEmail] = useState('');
  const userEmailInputRef = useRef<HTMLInputElement>(null);

  const [validateUserLoading, setValidateUserLoading] = useState(false);

  // ----------------------- VALIDATE USER QUERY -------------------- //

  const validateUser = async (variables: TeamFormValidateUserQueryVariables) => {
    try {
      setValidateUserLoading(true);

      const { data, errors } = await client.query<TeamFormValidateUserQueryData, TeamFormValidateUserQueryVariables>({
        variables,
        fetchPolicy: 'network-only',
        query: TEAM_FORM_VALIDATE_USER_QUERY,
      });

      if (errors) {
        throw new ApolloError({ graphQLErrors: errors });
      }

      return data.user;
    } catch (err) {
      if (isError(err)) {
        onError(err);
      }
    } finally {
      setValidateUserLoading(false);
    }

    return undefined;
  };

  const handleChangeUserEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserEmail(e.currentTarget.value);
  };

  const initialTeamOwner = team?.owner ?? user;

  const initialValues: TeamFormValues = useMemo(() => {
    const initialTeamUsers: Record<string, TeamUser> = {};

    team?.paginatedNonOwnerUsers.edges.forEach(({ node }) => {
      initialTeamUsers[node.id] = node;
    });

    const formValues: TeamFormValues = {
      teamName: team?.name ?? '',
      teamOwner: initialTeamOwner,
      teamUsers: initialTeamUsers,
    };

    return formValues;
  }, [initialTeamOwner, team]);

  const formik = useFormik({
    onSubmit,
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const {
    errors,
    setValues,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    dirty: formIsDirty,
    values: formikValues,
    initialValues: formikInitialValues,
  } = formik;
  /**
   * @author @Berhart
   * This useEffect is necessary to calculate a diff between the formik's current team users list
   * and the initial list such that, when a new list comes from props, a new initial state
   * can be re-computed
   * This is the first example at trying to solve the pagination issue inside the a form
   */
  useEffect(() => {
    /**
     * @author @Berhart
     * Im sorry but I couldnt find a way to avoid the calculation of the 'usersChanged'
     * every time a formik field changes.
     * The goal of this useEffect is to only execute its diff algorithm when the
     * the list of users coming from props differs from the one present on the
     * formik initial state
     */
    const formikInitialTeamUsers = formikInitialValues.teamUsers;
    const initialTeamUsersCopy = { ...initialValues.teamUsers };

    const formUsersInTeamUsers = Object.keys(formikInitialTeamUsers).every((userId) => userId in initialTeamUsersCopy);
    const teamUsersInFormUsers = Object.keys(initialTeamUsersCopy).every((userId) => userId in formikInitialTeamUsers);

    const usersChanged = !(formUsersInTeamUsers && teamUsersInFormUsers);

    if (usersChanged) {
      getObjectKeys(formikInitialTeamUsers).forEach((userId) => {
        if (userId in initialTeamUsersCopy) {
          delete initialTeamUsersCopy[userId];
        }
      });

      const newTeamUsers: TeamFormValues['teamUsers'] = {
        ...formikValues.teamUsers,
        ...initialTeamUsersCopy,
      };

      setValues({
        ...formikValues,
        teamUsers: newTeamUsers,
      });
    }
  }, [initialValues, formikValues, formikInitialValues, setValues]);

  const { teamName, teamOwner, teamUsers } = formikValues;

  const handleSubmitUserEmail = async () => {
    if (userEmail.length) {
      if (Object.values(teamUsers).find((teamUser) => teamUser.email === userEmail)) {
        enqueueSnackbar(formatMessage({ id: 'team-form.exist-email-warning' }), { variant: 'error' });
        return;
      }

      const validatedUser = await validateUser({
        email: userEmail,
      });

      if (isNull(validatedUser)) {
        enqueueSnackbar(formatMessage({ id: 'team-form.email-warning' }), { variant: 'error' });
      } else if (!isUndefined(validatedUser)) {
        setFieldValue('teamUsers', {
          ...teamUsers,
          [validatedUser.id]: validatedUser,
        });

        setUserEmail('');
      }

      const { current } = userEmailInputRef;
      if (current) {
        current.focus();
      }
    }
  };

  const handleDeleteTeamUser = (userToDelete: TeamUser) => {
    const newTeamUsers = { ...teamUsers };
    delete newTeamUsers[userToDelete.id];
    setFieldValue('teamUsers', newTeamUsers);
  };

  const handleMakeAdmin = (newOwner: TeamUser) => {
    const newTeamUsers = {
      ...teamUsers,
      [teamOwner.id]: teamOwner,
    };

    delete newTeamUsers[newOwner.id];
    setValues({
      ...formikValues,
      teamOwner: newOwner,
      teamUsers: newTeamUsers,
    });
  };

  // Set global Formik State
  GlobalFormikState.setDirty(formIsDirty);

  return (
    <StyledForm onSubmit={handleSubmit}>
      {errors.generic && <Alert severity="error">{errors.generic}</Alert>}
      <DefaultDialogContent>
        {canPerformChanges && (
          <>
            <TextField
              fullWidth
              name="teamName"
              value={teamName}
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              label={formatMessage({ id: 'team-form.place-holder-name' })}
              helperText={<CounterHelperText currentLength={teamName.length} maxLength={MAX_TEAM_NAME_LENGTH} />}
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={userEmail}
              inputRef={userEmailInputRef}
              onChange={handleChangeUserEmail}
              disabled={isSubmitting || validateUserLoading}
              label={formatMessage({ id: 'team-form.place-holder-user' })}
              helperText={
                <CounterHelperText
                  maxLength={MAX_TEAM_USER_EMAIL_LENGTH}
                  message={formatMessage({ id: 'team-form.helper-text' })}
                  currentLength={userEmailInputRef.current?.value.length ?? 0}
                />
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmitUserEmail();
                }
              }}
              InputProps={{
                endAdornment: (
                  <ProgressButton
                    disableElevation
                    variant="outlined"
                    disabled={!userEmail.length}
                    loading={isSubmitting || validateUserLoading}
                    onClick={() => handleSubmitUserEmail()}
                  >
                    {formatMessage({ id: 'shared.add' })}
                  </ProgressButton>
                ),
              }}
            />
          </>
        )}
        <TeamUsersList
          teamOwner={teamOwner}
          fetchMore={fetchMoreTeamUsers}
          handleMakeAdmin={handleMakeAdmin}
          teamUsers={Object.values(teamUsers)}
          fetchingMore={fetchingMoreTeamUsers}
          handleDeleteTeamUser={handleDeleteTeamUser}
          canEdit={isNull(team) || user.id === initialTeamOwner.id}
          hasMore={Boolean(team?.paginatedNonOwnerUsers.pageInfo.hasNextPage)}
        />
      </DefaultDialogContent>
      <DefaultDialogActions
        primaryButtonProps={{
          loading: isSubmitting,
          disabled: !teamName.trim().length || !formIsDirty || !canPerformChanges,
        }}
        secondaryButtonProps={{
          onClick: onCancelled,
          disabled: isSubmitting,
        }}
      />
    </StyledForm>
  );
};
