import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useUserTracking } from 'userTracking/useUserTracking';
import { canEnableIntercom } from 'shared/canEnableIntercom';
import { NotFound } from 'components/NotFound';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { ProtectedLayout } from 'components/Layouts/ProtectedLayout';
import { ProtectedRoutes } from 'components/App/ProtectedRoutes';

const LazyAuthPage = lazy(() => import('pages/Auth/AuthPage').then(({ AuthPage }) => ({ default: AuthPage })));

const LazyLogoutPage = lazy(() =>
  import('components/pages/Auth/LogoutPage').then(({ LogoutPage }) => ({ default: LogoutPage })),
);

const LazyDdtDashboard = lazy(() => import('ddt-dashboard').then(({ Index }) => ({ default: Index })));

const LazyManageFBPages = lazy(() =>
  import('pages/ManageFBPages/ManageFBPages').then(({ ManageFBPages }) => ({ default: ManageFBPages })),
);

export const MANAGE_FB_PAGE_PERMISSIONS_ROUTE_URL = 'manage-facebook-page-permissions';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

// For when external login pages redirect to /login_success
const SocialLoginSuccess: FunctionComponent = () => {
  useEffect(() => {
    window.localStorage.setItem('loginSuccessFlag', 'true');

    window.close();
  }, []);

  return null;
};

const Loading: FunctionComponent = () => (
  <StyledDiv>
    <CircularProgress size="5rem" thickness={2.5} />
  </StyledDiv>
);

export const AppRouting: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { captureEvent } = useUserTracking();
  const { boot: bootIntercom } = useIntercom();

  useEffect(() => {
    if (!canEnableIntercom()) return;

    bootIntercom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is a posthog event.
   *
   * @see {@link https://posthog.com/docs/integrate/client/js#one-page-apps-and-page-views}
   */
  useEffect(() => {
    captureEvent({
      anonymous: true,
      eventName: 'pageview',
      data: { url: pathname },
    });
  }, [pathname, captureEvent]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* Non-authenticated user routes */}
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route path="/login_success" element={<SocialLoginSuccess />} />
          <Route path="/auth/*" element={<LazyAuthPage />} />
        </Route>
        {/* Authenticated-users only */}
        <Route element={<ProtectedLayout />}>
          <Route path="*" element={<ProtectedRoutes />} />
        </Route>
        <Route path="/direct/*" element={<LazyDdtDashboard />} />
        <Route path="/auth/logout" element={<LazyLogoutPage />} />
        <Route path="/manage-facebook-page-permissions/*" element={<LazyManageFBPages />} />
        {/* Fallback: not found page */}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </Suspense>
  );
};
