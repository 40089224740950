import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Container, Divider, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { FunctionComponent, useContext, useMemo } from 'react';

import { LoadingBlock } from 'shared/LoadingBlock';
import { BillingProfileNode } from 'typeDeclarations/graphql/nodes';
import { ErrorMessage } from 'shared/ErrorMessage';
import { WalletManualTopUp } from './WalletManualTopUp/WalletManualTopUp';
import { WalletAutoTopup } from './WalletAutoTopup/WalletAutoTopup';
import { WalletVat } from './WalletVat/WalletVat';
import { WalletPaymentSources } from './WalletPaymentSources/WalletPaymentSources';
import { CreditLineCard } from './CreditLineCard/CreditLineCard';
import { InsufficientFundsWarningAlert } from 'components/Budget/InsufficientFundsWarningAlert';
import { walletVar } from './WalletDialog';
import { isError } from 'typeDeclarations/typeGuards';
import { logError } from 'utils/logging';
import { config } from 'appConfig';
import { LocaleContext } from 'i18n/LocaleContext';

interface WalletContentPaymentSourceData {
  sessionTeam: Node & {
    billingProfile: Pick<BillingProfileNode, 'id' | 'hasCreditLine' | 'primaryPaymentSource'>;
  };
}

const WALLET_CONTENT_PAYMENT_SOURCE = gql`
  query walletContentPaymentSource {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        hasCreditLine
        primaryPaymentSource {
          id
          modified
        }
      }
    }
  }
`;

export const WalletDialogContent: FunctionComponent = () => {
  const { issue } = useReactiveVar(walletVar);
  const { appLocale } = useContext(LocaleContext);

  const { data, loading, error } = useQuery<WalletContentPaymentSourceData>(WALLET_CONTENT_PAYMENT_SOURCE);

  function isStripeLocale(locale: string): locale is StripeElementLocale {
    const isLocale: PartialRecord<StripeElementLocale, boolean> = {
      es: true,
      en: true,
      pt: true,
      fr: true,
      nl: true,
      auto: true,
    };

    return locale in isLocale;
  }

  // FIXME Should come from our Stripe library
  const slicedLocale = appLocale.slice(0, 2);

  let stripeLocale: StripeElementLocale = 'auto';

  if (isStripeLocale(slicedLocale)) {
    stripeLocale = slicedLocale;
  } else {
    logError(new Error(`locale ${slicedLocale} is not supported by Stripe`));
  }

  const stripePromise = useMemo(
    () =>
      loadStripe(config.stripeKey, { locale: stripeLocale }).catch((err) => {
        if (isError(err)) {
          logError(err, {
            tags: {
              context: 'Stripe',
            },
          });
        } else {
          logError(new Error(JSON.stringify(err)));
        }

        return null;
      }),
    [stripeLocale],
  );

  if (loading) return <LoadingBlock />;

  if (error || !data) {
    return (
      <Container>
        <ErrorMessage />
      </Container>
    );
  }

  const { hasCreditLine, primaryPaymentSource } = data.sessionTeam.billingProfile;

  const hasPrimaryPaymentSource = primaryPaymentSource !== null;

  if (hasCreditLine) return <CreditLineCard />;

  return (
    <Stack rowGap={2}>
      {issue && <InsufficientFundsWarningAlert issue={issue} showLink={false} showDescription={false} />}
      <WalletPaymentSources />
      <Divider />
      <WalletVat />
      <Elements stripe={stripePromise}>
        <WalletManualTopUp disabled={!hasPrimaryPaymentSource} />
      </Elements>
      <Divider />
      <WalletAutoTopup disabled={!hasPrimaryPaymentSource} />
    </Stack>
  );
};
