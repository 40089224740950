import { gql, useLazyQuery } from '@apollo/client';

import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

const GET_SESSION_DATA_QUERY = gql`
  query getSessionData {
    sessionUser {
      id
      modified
      ddtId
      theme
      locale
      isStaff
    }
    sessionTeam {
      id
      modified
      owner {
        ddtId
      }
    }
  }
`;

interface GetSessionQueryData {
  sessionUser: Pick<UserNode, 'id' | 'ddtId' | 'theme' | 'locale' | 'isStaff'>;
  sessionTeam: Pick<TeamNode, 'id'> & { owner: Pick<UserNode, 'ddtId'> };
}

export const useGetSessionData = () => {
  return useLazyQuery<GetSessionQueryData>(GET_SESSION_DATA_QUERY);
};
